import labels from "./labels.json";
import * as StackBlur from 'stackblur-canvas';
// [
//   "FEMALE_GENITALIA_COVERED",
//   "FACE_FEMALE",
//   "BUTTOCKS_EXPOSED",
//   "FEMALE_BREAST_EXPOSED",
//   "FEMALE_GENITALIA_EXPOSED",
//   "MALE_BREAST_EXPOSED",
//   "ANUS_EXPOSED",
//   "FEET_EXPOSED",
//   "BELLY_COVERED",
//   "FEET_COVERED",
//   "ARMPITS_COVERED",
//   "ARMPITS_EXPOSED",
//   "FACE_MALE",
//   "BELLY_EXPOSED",
//   "MALE_GENITALIA_EXPOSED",
//   "ANUS_COVERED",
//   "FEMALE_BREAST_COVERED",
//   "BUTTOCKS_COVERED"
// ]

// "BUTTOCKS_EXPOSED",
const bannedAreas = [
  "FEMALE_BREAST_EXPOSED",
  "FEMALE_GENITALIA_EXPOSED",
  "ANUS_EXPOSED",
  "MALE_GENITALIA_EXPOSED",
];

// "BUTTOCKS_EXPOSED",
const bannedAreasStrict = [
  "FEMALE_BREAST_EXPOSED",
  "FEMALE_GENITALIA_EXPOSED",
  "ANUS_EXPOSED",
  "MALE_GENITALIA_EXPOSED",
  "BUTTOCKS_EXPOSED",
];

/**
 * Render prediction boxes
 * @param {HTMLCanvasElement} canvas canvas tag reference
 * @param {Array[Object]} boxes boxes array
 */
export const renderBoxes = (canvas, boxes, image, getCurrentBanedAreasArray, getCurrentPixelSize) => {

  const ctx = canvas.getContext("2d");
  //ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height); // clean canvas

  const colors = new Colors();

  // font configs
  const font = `${Math.max(
      Math.round(Math.max(ctx.canvas.width, ctx.canvas.height) / 40),
      14
  )}px Arial`;
  ctx.font = font;
  ctx.textBaseline = "top";

  const currentURL = window.location.href;
  const isStrictCensor = currentURL.includes("strict");

  boxes.forEach((box) => {
    const klass = labels[box.label];

    const color = colors.get(box.label);
    const score = (box.probability * 100).toFixed(1);
    const [x1, y1, width, height] = box.bounding;

    //const finalBannedAreas = isStrictCensor ? bannedAreasStrict : bannedAreas;
    const finalBannedAreas = getCurrentBanedAreasArray();
    if (finalBannedAreas.includes(klass)) {

      console.log("/////////////////////////////////");
      console.log("LABEL : " + klass);
      console.log("SCORE : " + score);
      console.log("/////////////////////////////////");

      const imageA = new Image();
      imageA.src = image.currentSrc;
      imageA.onload = function() {
        // Dessiner l'image sur le canvas
        //ctx.drawImage(imageA, 0, 0, canvas.width, canvas.height);

        // Récupérer les coordonnées de la zone sélectionnée
        //const { x, y, width, height } = e.target.getBoundingClientRect();
        const x = x1;
        const y = y1;
        // const width = canvas.width;
        // const height = canvas.height;

        // Pixeliser la zone en utilisant un algorithme simple
        //const pixelSize = 10;
        const pixelSize = getCurrentPixelSize();

        for (let i = x; i < x + width; i += pixelSize) {
          for (let j = y; j < y + height; j += pixelSize) {
            const pixelData = ctx.getImageData(i, j, pixelSize, pixelSize);

            // Calculer la valeur moyenne de chaque canal (rouge, vert, bleu) des pixels
            let avgR = 0, avgG = 0, avgB = 0;
            for (let k = 0; k < pixelData.data.length; k += 4) {
              avgR += pixelData.data[k];
              avgG += pixelData.data[k + 1];
              avgB += pixelData.data[k + 2];
            }
            avgR /= (pixelSize * pixelSize);
            avgG /= (pixelSize * pixelSize);
            avgB /= (pixelSize * pixelSize);

            // Appliquer la couleur moyenne à tous les pixels de la zone
            for (let k = 0; k < pixelData.data.length; k += 4) {
              pixelData.data[k] = avgR;
              pixelData.data[k + 1] = avgG;
              pixelData.data[k + 2] = avgB;
            }
            ctx.putImageData(pixelData, i, j);
          }
        }

      }








/*      //ctx.fillStyle = Colors.hexToRgba(color, 0.2);
      //ctx.fillStyle = Colors.hexToRgba("#000000", 1);
      //ctx.fillRect(x1, y1, width, height);

      if (klass == "FEMALE_GENITALIA_EXPOSED") {
        StackBlur.canvasRGBA(canvas, x1, y1, width, height+15, 15); // Le dernier paramètre est le rayon de flou
      }
      else if (klass == "ANUS_EXPOSED") {
       StackBlur.canvasRGBA(canvas, x1, y1, width, height+15, 15); // Le dernier paramètre est le rayon de flou
      }
      else if (klass == "BUTTOCKS_EXPOSED") {
        StackBlur.canvasRGBA(canvas, x1+(width/3.2), y1, width/2, height+15, 15); // Le dernier paramètre est le rayon de flou
      }
      else if (klass == "FEMALE_BREAST_EXPOSED") {
        StackBlur.canvasRGBA(canvas, x1+5, y1+5, width-10, height+15, 25); // Le dernier paramètre est le rayon de flou
      }
      else if (klass == "MALE_GENITALIA_EXPOSED") {
        StackBlur.canvasRGBA(canvas, x1, y1, width, height+10, 10); // Le dernier paramètre est le rayon de flou
      }
      else {
        StackBlur.canvasRGBA(canvas, x1, y1, width, height+15, 15); // Le dernier paramètre est le rayon de flou
      }
      // else {
      //   StackBlur.canvasRGBA(canvas, x1, y1, width, height, 5); // Le dernier paramètre est le rayon de flou
      // }



      //StackBlur.canvasRGBA(canvas, 100, 150, 100, 100, 20); // Le dernier paramètre est le rayon de flou
      //StackBlur.canvasRGBA(canvas, x1, y1, width+(width/10), height, 20); // Le dernier paramètre est le rayon de flou
      // StackBlur.canvasRGBA(canvas, 0, 0, 100, 100, 20); // Le dernier paramètre est le rayon de flou*/
    }

  });
};

export const renderBoxesOrig = (canvas, boxes) => {

  const ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height); // clean canvas

  const colors = new Colors();

  // font configs
  const font = `${Math.max(
    Math.round(Math.max(ctx.canvas.width, ctx.canvas.height) / 40),
    14
  )}px Arial`;
  ctx.font = font;
  ctx.textBaseline = "top";

  boxes.forEach((box) => {
    const klass = labels[box.label];

      const color = colors.get(box.label);
      const score = (box.probability * 100).toFixed(1);
      const [x1, y1, width, height] = box.bounding;

      // draw box.
      ctx.fillStyle = Colors.hexToRgba(color, 0.2);
      ctx.fillRect(x1, y1, width, height);

      // draw border box
      ctx.strokeStyle = color;
      ctx.lineWidth = Math.max(Math.min(ctx.canvas.width, ctx.canvas.height) / 200, 2.5);
      ctx.filter = 'none';
      ctx.strokeRect(x1, y1, width, height);

      // draw the label background.
      ctx.fillStyle = color;
      const textWidth = ctx.measureText(klass + " - " + score + "%").width;
      const textHeight = parseInt(font, 10); // base 10
      const yText = y1 - (textHeight + ctx.lineWidth);
      ctx.fillRect(
        x1 - 1,
        yText < 0 ? 0 : yText,
        textWidth + ctx.lineWidth,
        textHeight + ctx.lineWidth
      );

      // Draw labels
      ctx.fillStyle = "#ffffff";
      ctx.fillText(klass + " - " + score + "%", x1 - 1, yText < 0 ? 1 : yText + 1);

  });
};

class Colors {
  // ultralytics color palette https://ultralytics.com/
  constructor() {
    this.palette = [
      "#FF3838",
      "#FF9D97",
      "#FF701F",
      "#FFB21D",
      "#CFD231",
      "#48F90A",
      "#92CC17",
      "#3DDB86",
      "#1A9334",
      "#00D4BB",
      "#2C99A8",
      "#00C2FF",
      "#344593",
      "#6473FF",
      "#0018EC",
      "#8438FF",
      "#520085",
      "#CB38FF",
      "#FF95C8",
      "#FF37C7",
    ];
    this.n = this.palette.length;
  }

  get = (i) => this.palette[Math.floor(i) % this.n];

  static hexToRgba = (hex, alpha) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? `rgba(${[parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)].join(
          ", "
        )}, ${alpha})`
      : null;
  };
}
