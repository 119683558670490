import React, {useState, useRef, useEffect} from "react";
import cv from "@techstark/opencv-js";
import { Tensor, InferenceSession } from "onnxruntime-web";
import Loader from "./components/loader";
import { detectImage } from "./utils/detect";
import { download } from "./utils/download";
import "./style/App.css";

const App = () => {

    const [bannedAreaUi, setbannedAreaUi] = useState([
        "FEMALE_GENITALIA_EXPOSED",
        "MALE_GENITALIA_EXPOSED",
        "ANUS_EXPOSED",
        "FEMALE_BREAST_EXPOSED",
        "BUTTOCKS_EXPOSED",
    ]);

    const [session, setSession] = useState(null);
    const [loading, setLoading] = useState({ text: "Loading OpenCV.js", progress: null });
    const [image, setImage] = useState(null);
    const inputImage = useRef(null);
    const imageRef = useRef(null);
    const canvasRef = useRef(null);

    // Configs
    const modelName = "best.onnx";
    const modelInputShape = [1, 3, 320, 320];
    const topk = 100;
    const iouThreshold = 0.45;
    const scoreThreshold = 0.25;

    const columnSize = window.innerWidth / 3.2;

    const localStorageKey = 'RaZZIuM-AutoCensorIMG_checkBoxesData';
    const localStorageKeyBlurArea = 'RaZZIuM-AutoCensorIMG_BlurArea';
    const localStorageKeyBlurSize = 'RaZZIuM-AutoCensorIMG_BlurSize';
    const currentSavedCheckBoxes = JSON.parse(localStorage.getItem(localStorageKey));
    const currentPixelArea = JSON.parse(localStorage.getItem(localStorageKeyBlurArea));
    const currentPixelSize = JSON.parse(localStorage.getItem(localStorageKeyBlurSize));

    const [selectedValuePixelArea, setSelectedValuePixelArea] = useState(currentPixelArea ?? 5);

    const optionsPixelArea = [];
    for (let i = 1; i <= 10; i++) {
        optionsPixelArea.push(<option key={i} value={i}>{i}</option>);
    }

    const handleChangePixelArea  = (event) => {

        const checkPixelAreaChanged = JSON.stringify(currentPixelArea) !== JSON.stringify(parseInt(event.target.value));

        if (checkPixelAreaChanged) {
            localStorage.setItem(localStorageKeyBlurArea, JSON.stringify(parseInt(event.target.value)));
        }

        setSelectedValuePixelArea(parseInt(event.target.value));
    };

    const [selectedValuePixelSize, setSelectedValuePixeSize] = useState(currentPixelSize ?? 10);

    const optionsPixelSize = [];
    for (let i = 1; i <= 100; i++) {
        optionsPixelSize.push(<option key={i} value={i}>{i}</option>);
    }

    const handleChangePixelSize  = (event) => {

        const checkPixelSizeChanged = JSON.stringify(currentPixelSize) !== JSON.stringify(parseInt(event.target.value));

        if (checkPixelSizeChanged) {
            localStorage.setItem(localStorageKeyBlurSize, JSON.stringify(parseInt(event.target.value)));
        }

        setSelectedValuePixeSize(parseInt(event.target.value));

    };

    // RESET PREFS
    //localStorage.setItem(localStorageKey, JSON.stringify([]));

    const [checkBoxes, setCheckBoxes] = useState(currentSavedCheckBoxes != undefined && currentSavedCheckBoxes != null && currentSavedCheckBoxes.length > 0 ? currentSavedCheckBoxes : [
        { id: "FEMALE_GENITALIA_EXPOSED", label: ' PARTIES GÉNITALES FÉMININES EXPOSÉES', checked: true },
        { id: "MALE_GENITALIA_EXPOSED", label: ' PARTIES GÉNITALES MASCULINES EXPOSÉES', checked: true },
        { id: "ANUS_EXPOSED", label: ' ANUS EXPOSÉS', checked: true },
        { id: "FEMALE_BREAST_EXPOSED", label: ' SEINS EXPOSÉS', checked: true },
        { id: "BUTTOCKS_EXPOSED", label: ' FESSES EXPOSÉES', checked: true },
        { id: "FEET_EXPOSED", label: ' PIEDS EXPOSÉS', checked: false },
        { id: "ARMPITS_EXPOSED", label: ' AISSELLES EXPOSÉES', checked: false },
        { id: "MALE_BREAST_EXPOSED", label: ' TORSES MASCULINS EXPOSÉS', checked: false },
        { id: "BELLY_EXPOSED", label: ' VENTRES EXPOSÉS', checked: false },
        { id: "FEMALE_GENITALIA_COVERED", label: ' PARTIES GÉNITALES FÉMININES COUVERTES', checked: false },
        { id: "BELLY_COVERED", label: ' VENTRES COUVERTS', checked: false },
        { id: "FEET_COVERED", label: ' PIEDS COUVERTS', checked: false },
        { id: "ARMPITS_COVERED", label: ' AISSELLES COUVERTES', checked: false },
        { id: "ANUS_COVERED", label: ' ANUS COUVERTS', checked: false },
        { id: "FEMALE_BREAST_COVERED", label: ' SEINS COUVERTS', checked: false },
        { id: "BUTTOCKS_COVERED", label: ' FESSES COUVERTES', checked: false },
        { id: "FACE_FEMALE", label: ' VISAGES', checked: false },
        // { id: "FACE_FEMALE", label: ' VISAGES FÉMININS', checked: false },
        // { id: "FACE_MALE", label: ' VISAGES MASCULINS', checked: false },
    ]);


    useEffect(() => {
        let tmpBannedAreaUi = [];

        // Vérifiez si les valeurs de checkBoxes ont changé par rapport aux valeurs précédentes dans localStorage
        const previousCheckBoxes = JSON.parse(localStorage.getItem(localStorageKey)) || [];
        const checkBoxesHaveChanged = JSON.stringify(previousCheckBoxes) !== JSON.stringify(checkBoxes);

        if (checkBoxesHaveChanged) {
            localStorage.setItem(localStorageKey, JSON.stringify(checkBoxes));
        }

        checkBoxes.forEach((item, index) => {
            if (item.checked) {
                tmpBannedAreaUi.push(item.id);
            }
        });

        setbannedAreaUi(tmpBannedAreaUi);
    }, [checkBoxes]);

    const getCurrentBanedAreasArray = () => {
        return bannedAreaUi;
    };

    const getCurrentPixelArea = () => {
        return selectedValuePixelArea;
    };

    const getCurrentPixelSize = () => {
        return selectedValuePixelSize;
    };

    const handleCheckBoxChange = (id) => {
        setCheckBoxes((prevState) =>
            prevState.map((checkbox) =>
                checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
            )
        );
    };

    // wait until opencv.js initialized
    cv["onRuntimeInitialized"] = async () => {
        const baseModelURL = `${process.env.PUBLIC_URL}/model`;

        // create session
        const arrBufNet = await download(
            `${baseModelURL}/${modelName}`, // url
            ["Loading RaZZIuM - AutoCensorIMG", setLoading] // logger
        );
        const yolov8 = await InferenceSession.create(arrBufNet);
        const arrBufNMS = await download(
            `${baseModelURL}/nms-yolov8.onnx`, // url
            ["Loading NMS model", setLoading] // logger
        );
        const nms = await InferenceSession.create(arrBufNMS);

        // warmup main model
        setLoading({ text: "Warming up model...", progress: null });
        const tensor = new Tensor(
            "float32",
            new Float32Array(modelInputShape.reduce((a, b) => a * b)),
            modelInputShape
        );
        await yolov8.run({ images: tensor });

        setSession({ net: yolov8, nms: nms });
        setLoading(null);
    };

    return (
        <div className="App">
            {loading && (
                <Loader>
                    {loading.progress ? `${loading.text} - ${loading.progress}%` : loading.text}
                </Loader>
            )}
            <div className="header">
                <h1>RaZZIuM - AutoCensorIMG</h1>
            </div>

            <div className="content">
                <img
                    ref={imageRef}
                    src="#"
                    alt=""
                    style={{ display: image ? "block" : "none" }}
                    onLoad={() => {

                        detectImage(
                            imageRef.current,
                            canvasRef.current,
                            session,
                            topk,
                            iouThreshold,
                            scoreThreshold,
                            getCurrentBanedAreasArray,
                            getCurrentPixelArea,
                            getCurrentPixelSize,
                            modelInputShape
                        );
                    }}
                />
                <canvas
                    id="canvas"
                    width={modelInputShape[2]}
                    height={modelInputShape[3]}
                    ref={canvasRef}
                />
            </div>

            <input
                type="file"
                ref={inputImage}
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => {
                    // handle next image to detect
                    if (image) {
                        URL.revokeObjectURL(image);
                        setImage(null);
                    }

                    const url = URL.createObjectURL(e.target.files[0]); // create image url
                    imageRef.current.src = url; // set image source
                    setImage(url);

                    // const imageA = new Image();
                    // imageA.src = url;
                    // imageA.onload = function() {
                    //
                    //
                    //
                    //
                    //     const canvas = canvasRef.current;
                    //     const ctx = canvasRef.current.getContext("2d");
                    //     // Dessiner l'image sur le canvas
                    //     ctx.drawImage(imageA, 0, 0, canvas.width, canvas.height);
                    //
                    //     // Récupérer les coordonnées de la zone sélectionnée
                    //     //const { x, y, width, height } = e.target.getBoundingClientRect();
                    //     const x = 0;
                    //     const y = 0;
                    //     const width = canvas.width;
                    //     const height = canvas.height;
                    //
                    //     // Pixeliser la zone en utilisant un algorithme simple
                    //     const pixelSize = 10;
                    //     for (let i = x; i < x + width; i += pixelSize) {
                    //         for (let j = y; j < y + height; j += pixelSize) {
                    //             const pixelData = ctx.getImageData(i, j, pixelSize, pixelSize);
                    //
                    //             // Calculer la valeur moyenne de chaque canal (rouge, vert, bleu) des pixels
                    //             let avgR = 0, avgG = 0, avgB = 0;
                    //             for (let k = 0; k < pixelData.data.length; k += 4) {
                    //                 avgR += pixelData.data[k];
                    //                 avgG += pixelData.data[k + 1];
                    //                 avgB += pixelData.data[k + 2];
                    //             }
                    //             avgR /= (pixelSize * pixelSize);
                    //             avgG /= (pixelSize * pixelSize);
                    //             avgB /= (pixelSize * pixelSize);
                    //
                    //             // Appliquer la couleur moyenne à tous les pixels de la zone
                    //             for (let k = 0; k < pixelData.data.length; k += 4) {
                    //                 pixelData.data[k] = avgR;
                    //                 pixelData.data[k + 1] = avgG;
                    //                 pixelData.data[k + 2] = avgB;
                    //             }
                    //             ctx.putImageData(pixelData, i, j);
                    //         }
                    //     }
                    //
                    //     // const ctx = canvasRef.current.getContext("2d");
                    //     // //ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height); // clean canvas
                    //     // ctx.drawImage(imageA, 0, 0, ctx.canvas.width, ctx.canvas.height);
                    // };
                }}
            />
            <div className="btn-container">
                <button
                    onClick={() => {
                        inputImage.current.value = "";
                        imageRef.current.src = "#";
                        if (image) {
                            URL.revokeObjectURL(image);
                            setImage(null);
                        }

                        inputImage.current.click();
                    }}
                >
                    Open local image
                </button>
                {image && (
                    /* show close btn when there is image */
                    <button
                        onClick={() => {
                            inputImage.current.value = "";
                            imageRef.current.src = "#";
                            URL.revokeObjectURL(image);
                            setImage(null);
                        }}
                    >
                        Close image
                    </button>
                )}
            </div>
            <br/>
            <br/>
            <br/>
            <div className="btn-container">
                <label>
                    Critères de censure
                </label>
                <br/>
                <br/>
            </div>
            <div style={{display:"flex"}}>

                <div style={{flex:1, width: columnSize, flexDirection:'column', display:"flex"}}>
                    {checkBoxes.slice(0, 9).map((checkbox) => (
                        <label key={checkbox.id}>
                            <input
                                type="checkbox"
                                checked={checkbox.checked}
                                onChange={() => handleCheckBoxChange(checkbox.id)}
                            />
                            {checkbox.label + " "}&emsp;
                        </label>
                    ))}
                </div>


                <div style={{flex:1, width: columnSize,  flexDirection:'column', display:"flex"}}>
                    {checkBoxes.slice(9, 16).map((checkbox) => (
                        <label key={checkbox.id}>
                            <input
                                type="checkbox"
                                checked={checkbox.checked}
                                onChange={() => handleCheckBoxChange(checkbox.id)}
                            />
                            {checkbox.label + " "}&emsp;
                        </label>
                    ))}
                </div>


                <div style={{flex:1, width: columnSize,  flexDirection:'column', display:"flex"}}>
                    {checkBoxes.slice(16, 17).map((checkbox) => (
                        <label key={checkbox.id}>
                            <input
                                type="checkbox"
                                checked={checkbox.checked}
                                onChange={() => handleCheckBoxChange(checkbox.id)}
                            />
                            {checkbox.label + " "}&emsp;
                        </label>
                    ))}
                </div>

            </div>
            <br/>
            <br/>
            <br/>
            <div className="btn-container">
                <label>
                    Zone de pixélisation (défaut : 5)
                </label>
                <br/>
            </div>
            <div>
                <select value={selectedValuePixelArea} onChange={handleChangePixelArea}>
                    {optionsPixelArea}
                </select>
            </div>
            <br/>
            <div className="btn-container">
                <label>
                    Taille de pixel (défaut : 10)
                </label>
                <br/>
            </div>
            <div>
                <select value={selectedValuePixelSize} onChange={handleChangePixelSize}>
                    {optionsPixelSize}
                </select>
            </div>
        </div>
    );
};

export default App;
